// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
  height: 100%;
  margin: 0;
}

body {
  background: linear-gradient(to bottom, black, #022c43) !important;
  overflow: hidden;
  display: block;
  font: 300 11px/1.4 'Helvetica Neue', 'sans-serif';
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,SAAS;AACX;;AAEA;EACE,iEAAiE;EACjE,gBAAgB;EAChB,cAAc;EACd,iDAAiD;EACjD,WAAW;EACX,mCAAmC;EACnC,kCAAkC;AACpC","sourcesContent":["html, body {\n  height: 100%;\n  margin: 0;\n}\n\nbody {\n  background: linear-gradient(to bottom, black, #022c43) !important;\n  overflow: hidden;\n  display: block;\n  font: 300 11px/1.4 'Helvetica Neue', 'sans-serif';\n  color: #444;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
