// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes flipIn {
  0% {
    opacity: 0;
    transform: rotateY(90deg) rotateZ(30deg);
  }
  100% {
    opacity: 1;
    transform: rotateY(0deg) rotateZ(30deg);
  }
}
.logo-container {
  z-index: 0;
  width: 400px;
  height: 609px;
  position: absolute;
  top: 0;
  right: 15%;
  bottom: 0;
  left: auto;
  margin: auto;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.logo-container.visible {
  opacity: 1;
}

.solid-logo {
  position: absolute;
  top: auto;
  right: auto;
  bottom: auto;
  left: 0;
  margin: auto;
  width: 100%;
  opacity: 0;
  transform: rotateY(90deg) rotateZ(30deg);
  z-index: 1;
}

.solid-logo.visible {
  opacity: 1;
  animation: flipIn 2s forwards;
}`, "",{"version":3,"sources":["webpack://./src/components/Home/Logo/index.scss"],"names":[],"mappings":"AAAA;EACI;IACI,UAAA;IACA,wCAAA;EACN;EACE;IACI,UAAA;IACA,uCAAA;EACN;AACF;AAEA;EACI,UAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,MAAA;EACA,UAAA;EACA,SAAA;EACA,UAAA;EACA,YAAA;EACA,UAAA;EACA,kCAAA;AAAJ;;AAGA;EACI,UAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,OAAA;EACA,YAAA;EACA,WAAA;EACA,UAAA;EACA,wCAAA;EACA,UAAA;AAAJ;;AAGA;EACI,UAAA;EACA,6BAAA;AAAJ","sourcesContent":["@keyframes flipIn {\n    0% {\n        opacity: 0;\n        transform: rotateY(90deg) rotateZ(30deg);\n    }\n    100% {\n        opacity: 1;\n        transform: rotateY(0deg) rotateZ(30deg);\n    }\n}\n\n.logo-container {\n    z-index: 0;\n    width: 400px;\n    height: 609px;\n    position: absolute;\n    top: 0;\n    right: 15%;\n    bottom: 0;\n    left: auto;\n    margin: auto;\n    opacity: 0;\n    transition: opacity 1s ease-in-out;\n}\n\n.logo-container.visible {\n    opacity: 1;\n}\n\n.solid-logo {\n    position: absolute;\n    top: auto;\n    right: auto;\n    bottom: auto;\n    left: 0;\n    margin: auto;\n    width: 100%;\n    opacity: 0;\n    transform: rotateY(90deg) rotateZ(30deg);\n    z-index: 1;\n}\n\n.solid-logo.visible {\n    opacity: 1;\n    animation: flipIn 2s forwards;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
